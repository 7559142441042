.cf_size {
	&--xs {
		font-size: 14px;
		line-height: 18px;
	}
	&--sm {
		font-size: 16px;
		line-height: 22px;
	}
	&--md {
		font-size: 18px;
		line-height: 22px;
	}
	&--bg {
		font-size: 24px;
		line-height: 28px;
	}
	@media screen and (max-width: $screen-sm-max) {
		&--sm {
			font-size: 13px;
			line-height: 16px;
		}
		&--md {
			font-size: 15px;
			line-height: 19px;
		}
		&--bg {
			font-size: 17px;
			line-height: 20px;
		}
	}
}

.cf_weight {
	&--sm {
		font-weight: 400;
	}
	&--md {
		font-weight: 500;
	}
	&--bg {
		font-weight: 700;
	}
}

.cf_color {
	&--white {
		color: $white;
	}
	&--blue {
		color: $bleu;
	}
	&--blue_dark {
		color: $bleu_dark;
	}
	&--orange {
		color: $orange;
	}
	&--light-grey {
		color: $lightGrey;
	}
	&--orange-light {
		color: $orange-light;
	}
	&--grey {
		color: $grey;
	}
	&--green {
		color: $green;
	}
}

/*** modifier ***/
.cf_center {
	text-align: center;
}
.cf_text_shadow {
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	letter-spacing: 0.5px;
}
.cf_upp {
	text-transform: uppercase;
}

.cf_shadow {
	box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.2);
}

.cf_text_underline {
	display: inline-block;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		background-color: $orange;
		bottom: -5px;
		top: -5px;
		left: -10px;
		right: -10px;
		z-index: -1;
		opacity: 0.9;
	}
}

/*** COLOR ***/
.cf_bgc {
	&--black {
		background-color: $black;
	}
	&--white {
		background-color: $white;
	}
	&--blue {
		background-color: $bleu;
	}
}

.cf-citation {
	font-size: 24px;
	font-style: italic;
	line-height: 37px;
	position: relative;
	margin-bottom: 30px;
	&:before {
		content: '"';
		z-index: 1;
		position: absolute;
		left: -70px;
		top: 0;
		opacity: 0.1;
		font-size: 120px;
	}
	&:after {
		content: '"';
		z-index: 1;
		position: absolute;
		right: -70px;
		bottom: -50px;
		opacity: 0.1;
		font-size: 120px;
	}
}
.cf-list {
	padding-top: 40px;
	padding-left: 20px;
	li {
		padding-bottom: 20px;
		font-size: 15px;
	}
}
