body.page-template-page-about{
	transition: 0.4s $easeInOutQuart;
}

.list_team{
	margin: auto;
	padding: none;
	transition: 0.4s $easeInOutQuart;

	.item_team{
		float:left;
		width: 25%;
		margin: 10px auto;
        overflow: hidden; 

		@media screen and (max-width: $screen-md-max) {
			width: 33%;
		}
		@media screen and (max-width: $screen-sm-max) {
			width: 50%;
  		}
  		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
  		}


		.item_team__square{
			width: 260px;
			height: 260px;
	        margin: auto !important;
	        position: relative;

  			&-bg{
  				height: 260px;
				width: 260px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-color: rgba($black,0.1);
  				position: relative;



  			}

			// block Hover
			&-hover{
				height: 100%;
				width: 100%;
				padding: 20px;
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				text-align: center;
				opacity: 0;
				transition: 0.6s $easeOutQuart;

				&:before{
					content :'';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 2;
					transition: 0.6s $easeOutQuart;
				}

				&:after{
					content :'';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					background: rgba($black, 0.3);
					opacity: 0;
					transition: 0.6s $easeOutQuart;
				}
				div.content{
					width: calc(100% - 25px) ;
			        margin: auto;
			        position: absolute;
			        left: 0;
			        right: 0;
				}
			}
			// block active
			&-active{
				opacity: 0;
		        position: absolute;
		        top: 0;
		        left: 0;
		        bottom: 0;
		        right: 0;
		        background: $orange;
		        padding: 15px 15px 15px 33%;
				z-index: -1;
				transition: 0.4s $easeOutQuart;
				width: 100%;

				@media screen and (max-width: $screen-md-max) {
					padding-left: 33%;
				}
				@media screen and (max-width: $screen-sm-max) {
			        position: fixed;
			        top: 0;
			        height: 100%;
			        width: 100%;
			        opacity: 0;
			        visibility: hidden;
			        padding: 0;
			        z-index: -1;
			        padding: 70px 25px 25px 25px;
			        overflow: hidden;

		  		}
		  		@media screen and (max-width: $screen-xs-max) {
		  		}

		  		.btn--close-modal{
		  			position: absolute;
			        top: 15px;
			        right: 15px;
			    }



				.item_team__square-active_content{
					overflow: scroll;
					height: 100%;
				}
				.item_team__square-active_info{

				}
				.item_team__square-active_detail{
					.picto{
						max-height: 20px;
						margin-bottom: 10px;
					}
				}
			}


			// en fonction du positionnement




			&:hover{
				.item_team__square-hover{
					opacity: 1;
					&:before{
						border: 15px solid $orange;
					}
					&:after{
						opacity: 1;
					}
				}

			}

		}

		&.active{
			width: 75%;
			@media screen and (max-width: $screen-md-max) {
				width: 100%;
			}
			@media screen and (max-width: $screen-sm-max) {
				width: 50%;
			}
			@media screen and (max-width: $screen-xs-max) {
				width: 100%;
			}

			.item_team__square{
			}

			.item_team__square-bg{
				z-index: 40;
			}
			.item_team__square-hover{
		        z-index: 40;
		        opacity: 1;
		        .content{
		        	opacity: 0;
		        }
				&:before{
					border: 15px solid $orange;
				}
				&:after{
					opacity: 0;
				}
			}
			.item_team__square-active{
				opacity: 1;
        		z-index: 10;
				@media screen and (max-width: $screen-sm-max) {
        			z-index: 9999;
        			visibility: visible;
				}
			}


			// hover lorsque l'element est ouvert
			&:hover{
				.item_team__square-hover{
					&:after{
						opacity: 0;
					}
				}

			}

		}

		&--right{
			.item_team__square-active{
				right: 0;
	        	padding: 15px 100% 15px 15px  ;
			}
		}






	}



}
