.cf_section{
	padding: 100px 0;
	&--sPad{
		padding: 30px 0 ;
	}
	@media screen and (max-width: $screen-xs-max) {
		padding: 50px 0;
	}
}

.cf_section--top{
	margin-top: 100px;
    padding-top: 50px;
	@media screen and (max-width: $screen-md-max) {
		margin-top: 70px;
	}
	@media screen and (max-width: $screen-sm-max) {
		margin-top: 50px;
	}
}

.cf_section--about{
	position: relative;

	.cf_section--about__legend-image {
		max-width: 85px;
		margin-left: 5px;
	}

	@media screen and (max-width: $screen-xs-max) {
		padding-bottom: 0px;
	}
}

.cf_section--question{
	background-repeat: no-repeat; 
	background-position: center center;
	background-size: cover;  
}

.cf_section--contact{
	min-height: calc(100vh - 100px );
	display: flex; 
    padding-bottom: 0;
    align-items: center;
    position: relative;
	@media screen and (max-width: $screen-xs-max) {

		display: block;
    }
}