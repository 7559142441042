body.single-post{
	.cf_news--title{
		width: 80%;
        margin-left: 0;
        @media screen and (max-width: $screen-sm-max){
			width: 100%;
		}
	}
	.article-body{
		width: 90%;

		@media screen and (max-width: $screen-sm-max){
			width: 100%;
		}
	}

	.list-inline {
		margin-bottom: 0px;
	}
}

.article-body{
	font-size: 18px;
  	color: #000000;
  	font-weight: 400;
	
	@extend .entry-content;
	b{
		background: $orange;
		color: $white;
		font-weight: 300;
		padding-left: 5px;
		padding-right: 5px;
	}
}
