.articles {
	clear: both;
	.cf_title {
		margin: 50px 0;
	}
}
.other-article {
	transition: all ease 0.3s;
	margin-bottom: 30px;
	text-decoration: none;
	@extend .col-xs-12;

	.img {
		width: 100%;
		height: 450px;
		background-size: cover;
		background-position: center;
		display: block;
		padding: 50px;
		background-color: $grey;
		transition: 0.4s $easeInOutQuart;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $black;
			z-index: 1;
			opacity: 0;
			transition: 0.3s lienar;
		}
		&:after {
			content: "";
			position: absolute;
			background-color: $orange;
			left: 2px;
			height: 0;
			width: 5px;
			bottom: 0;
			margin: auto;
			z-index: 2;
			transition: 0.3s $easeInOutQuart 0.05s;
			transform: translateX(-2px);
		}

		.date {
			opacity: 0.8;
			color: $white;
			font-size: 15px;
			text-transform: uppercase;
			z-index: 10;
			position: relative;
			display: block;
			transition: 0.5s $easeInOutQuart 0.05s;
		}

		.title {
			text-align: left;
			font-size: 30px;
			font-weight: 400;
			padding: 0px;
			width: 100%;
			line-height: 38px;
			display: block;
			text-decoration: none;
			color: $white;
			margin-top: 10px;
			position: relative;
			z-index: 10;
			transition: 0.6s $easeInOutQuart;
		}

		&:hover {
			text-decoration: none;
			box-shadow: $shadow_box;
			&:before {
				opacity: 0.4;
			}
			&:after {
				height: 100%;
			}
			.date {
				transform: translateY(30px);
			}
			.title {
				transform: translateY(20px);
			}
		}
	}

	&--1,
	&--4 {
		@extend .col-sm-4;
	}
	&--2,
	&--3 {
		@extend .col-sm-8;
	}
	&--5,
	&--6,
	&--7 {
		@extend .col-sm-4;
	}
}
