.cf_docs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 40px 0;
	padding: 0px;
	align-items: flex-start;
	.cf_docs_item {
		padding: 0 20px 20px 20px;
		width: 50%;
		list-style: none;

		@media screen and (max-width: $screen-sm-max) {
			width: 100%;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 20px;
			transition: 0.2s $easeOutQuart;
			&:hover {
				@extend .cf_shadow;
				text-decoration: none;
			}
			.cf_docs_item_title {
				min-height: 40px;
			}
			.cf_docs_item_type {
				margin-bottom: 5px;
			}
		}
	}

	&--full {
		.cf_docs_item {
			width: 25%;
			@media screen and (max-width: $screen-md-max) {
				width: 25%;
			}
			@media screen and (max-width: $screen-sm-max) {
				width: 50%;
				padding: 0;
			}
		}
	}
}

.list_file {
	margin: 10px 0px;
	padding: 0px;
	list-style: none;
	.list_file-item {
		list-style: none;
	}
}

.ico {
	display: block;
	width: 90px;
	height: 90px;
	margin: 0 auto 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40px;
	background-image: url("../img/icons/doc_file.svg");
	&--reporting {
		background-image: url("../img/icons/doc_reporting.svg");
	}
	&--fiche-commerciale {
		background-image: url("../img/icons/doc_reporting.svg");
	}
	&--fiche-mensuelle {
	}
	&--gestion-des-plaintes-clients {
		background-size: 60px;
		background-image: url("../img/icons/doc_customer.svg");
	}

	&--small {
		width: 30px;
		height: 30px;
		background-size: 20px;
	}
}

// .full-filters{
//     @media screen and (max-width: $screen-sm-max) {
//         display: none;
//     }
// }

.swiper-filter {
	display: none;
	@media screen and (max-width: $screen-sm-max) {
		display: block;
	}
	position: relative;
	padding: 0 30px;

	.swiper-wrapper {
		height: auto;

		.swiper-slide {
			height: auto;

			.item-link {
				font-family: $font_play;
				font-weight: 500;
				text-align: center;
				display: inline-block;
				color: #ff8b03;
				font-size: 18px;
				text-decoration: none;

				&.selected {
					background-color: $orange;
					color: #fff;
					padding: 10px 20px;
				}
			}
		}
	}
	.arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: $orange;
		cursor: pointer;
		display: inline-block;
		font-size: 20px;

		&.arrow-left {
			left: 0;
		}

		&.arrow-right {
			right: 0;
		}

		&:focus {
			outline: none;
		}
	}
}
