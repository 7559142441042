.btn {
	border: 2px solid black;
	padding: 8px 25px;
	border-radius: 50px;
	@extend .cf_size--sm;
	@extend .cf_weight--sm;
	transition: 0.2s ease-in-out;
	&:hover {
		box-shadow: 0 6px 20px 5px rgba(132, 132, 132, 0.34);
	}
}
/** TAILLE **/
.btn {
	&--large {
		padding: 8px 50px;
	}
	&--big {
		@extend .cf_size--md;
	}
}

/** COLOR **/
.btn {
	/** BTN EN FULL **/
	&--forange {
		background: $orange;
		color: $white;
		border-color: $orange;
		&:hover {
			color: $white;
		}
	}

	&--fblue {
		background: $bleu;
		color: $white;
		border-color: $bleu;
		&:hover {
		}
	}

	&--fblue-dark {
		background: $bleu_dark;
		color: $white;
		border-color: $bleu_dark;

		&:hover {
			color: $white;
		}
	}

	&--fwhite {
		background: $white;
		color: $bleu;
		border-color: $white;
	}
	&--fgreen {
		background: $green;
		color: $white;
		border-color: $green;
		&:hover {
		}
	}
	/** BTN LIGHT **/
	&--lorange {
		background: transparent;
		color: $orange;
		border-color: $orange;
		&:hover {
		}
	}

	&--lblue {
		background: transparent;
		color: $bleu;
		border-color: $bleu;
		&:hover {
			color: $bleu;
		}
	}

	&--lblue-dark {
		background: transparent;
		color: $bleu_dark;
		border-color: $bleu_dark;

		&:hover {
			color: $bleu_dark;
		}
	}
}

/** btn ion **/
.btn-icon {
	position: relative;
	.arrow {
		position: absolute;
		width: 15px;
		height: 20px;
		margin-top: auto;
		margin-bottom: auto;
		> svg {
			width: 15px;
			height: 20px;
		}
	}
	&--left {
		padding-left: 40px;
		.arrow {
			transform: rotate(180deg);
			left: 10px;
		}
	}
	&--right {
		padding-right: 40px;
		.arrow {
			right: 10px;
		}
	}
}

.btn-squarred,
.btn-squarred:active,
.btn-squarred:visited {
	font-family: $font_defautl;
	font-weight: 500;
	text-align: center;
	display: inline-block;
	color: lighten($orange, 10%);
	transition: all 0.4s ease-out;
	padding: 25px 40px;
	font-size: 18px;
	text-decoration: none;

	&.selected {
		background-color: $orange;
		color: #fff;

		&:hover {
			text-decoration: none;
			color: #fff;
			box-shadow: none;
		}
	}

	&:hover {
		text-decoration: none;
		color: lighten($orange, 10%);
		box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.15);
	}
}

/** SPECIFIQUE **/
.btn--linkedin {
	background: $color-linkedin;
	color: $white;
	position: relative;
	padding-right: 50px;
	border-color: $color-linkedin;
	.before-ico {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 32px;
		width: 32px;
		margin: 3px;
		border-radius: 100%;
		background: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			color: $color-linkedin;
			width: 15px;
			height: 15px;
		}
	}
}

.btn-status {
	cursor: pointer;
}
