
body.page-template-page-contact{

}
.list_contact{
	list-style: none; 
	padding: 50px 30px;  
	margin: 0px; 
	position: relative;
	z-index: 20;
	&:before{
		content: ''; 
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: $white; 
	}
	&:after{
		content: ''; 
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 10%;
		box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.3);
        z-index: -1;


		@media screen and (max-width: $screen-xs-max) {
			top: auto;
			left: 0;
			height: 150px;
			width: 100%;
        }
	}
	.list_contact-item{
		list-style: none; 
		margin-bottom: 50px;
		position: relative;
		z-index: 5;

		> p{
			letter-spacing: 1px;
	        margin-bottom: 2px;
		}

		.content{
			p, a{
				text-decoration: none;
				color: $bleu_dark;
				line-height: 28px;
				margin: 0;
			}
		}
		&:last-child{
			margin-bottom: 0px;
		}
		@media screen and (max-width: $screen-sm-max) {

		}
		@media screen and (max-width: $screen-xs-max) {

			
		}
	}

	@media screen and (max-width: $screen-xs-max) {
		top: 40px;
	}
}

.ctr_maps{
	position: absolute; 
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-left: 30%;

	@media screen and (max-width: $screen-sm-max) {
		padding-left: 40%;
    }
	@media screen and (max-width: $screen-xs-max) {
		position: relative;
        padding: 0;
        top: auto;
        height: 400px;
        left: -15px;
        width: calc(100% + 30px);
        bottom: 0;
	}
	#map{
		height: 100%;
		width: 100%;
		filter: grayscale(1);
	}
}