.header {
	.navbar-default {
		border: 0px;
		padding: 10px 0;
		background-color: $bleu_dark;
		.navbar-nav > li {
			overflow: hidden;
			&.current_page_item {
				&:after {
					height: 2px;
				}
			}

			&:after {
				content: "";
				position: absolute;
				background-color: $orange;
				bottom: 0;
				left: 10px;
				right: 10px;
				height: 0px;
				z-index: -1;
				opacity: 1;
				transition: 0.3s $easeOutQuart;
				@media screen and (max-width: $screen-xs-max) {
					left: 0px;
					right: 0px;
				}
			}
			&:before {
				content: "";
				position: absolute;
				display: block;
				margin: 0 5px;
				height: 50%;
				width: 1px;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				background-color: $white;

				@media screen and (max-width: $screen-sm-max) {
					display: none;
				}
			}
			> a {
				color: $white;
				padding: 5px 15px;
				font-family: $font_play;
				font-weight: 300;
				letter-spacing: 1px;
				@media screen and (max-width: $screen-xs-max) {
					padding: 10px 0px;
				}
			}
			&:hover {
				&:after {
					height: 18px;
				}
			}

			&:first-child {
				&:before {
					display: none;
				}
			}
		}
		.navbar-collapse {
			border: 0px;
			box-shadow: none;
		}
		.logo_svg {
			height: 50px;
			// max-width: 124px;

			@media screen and (max-width: $screen-sm-max) {
				width: 85px;
				position: absolute;
				top: 20px;
				left: 20px;
			}
			@media screen and (max-width: $screen-xs-max) {
				width: 60px;
				margin-left: 16px;
				position: relative;
				top: auto;
				left: auto;
			}
		}
	}
}

.dm-menu {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	@media screen and (max-width: $screen-sm-max) {
		flex-direction: column;
	}
	.dm-menu_sup {
		padding: 5px 0;
		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
			order: 2;
		}
	}
	.dm-menu_sub {
		padding: 5px 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
			order: 1;
			flex-direction: column;
			.nav {
				width: 100%;
			}
		}
		.btn {
			padding: 5px 40px;
			margin-left: 15px;
			@media screen and (max-width: $screen-xs-max) {
				margin: 10px auto 0 auto;
			}
		}
	}
}

#nav__top {
	> li {
		overflow: visible;
		&:after {
			display: none;
		}
		> a {
			line-height: 18px;
			font-size: 13px;
			height: 26px;
		}

		&.open {
			> a {
				background-color: transparent;
				color: $white;
			}
		}
	}
}

#nav__top--sm {
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
}

.lang-item {
	img {
		position: relative;
		top: -2px;
		padding-left: 5px;
	}
}

.dropdown {
	&:hover {
		@extend .open;
		@media screen and (max-width: $screen-xs-max) {
			display: none !important;
		}
	}
	.dropdown-toggle {
		padding-right: 35px !important;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 5px;
			width: 25px;
			background-image: url("../img/icons/arrow.svg");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 18px;
			transition: 0.4s $easeInOutQuart;
			transform: rotate(90deg);
		}
	}

	&:hover,
	.open {
		.dropdown-toggle {
			&:after {
				transform: rotate(-90deg);
			}
		}
	}
}

.dropdown-menu {
	border-radius: 0px;
	border: 0px;
	min-width: 100%;
	margin-top: 7px;
	text-align: center;
	background-color: #ffffff;

	@media screen and (max-width: $screen-xs-max) {
		display: block;
		position: relative;
	}
}

.navbar-default .navbar-toggle {
	border: 0px;
	margin-top: 0;
	margin-bottom: 0;
	border-radius: 0px;
	.icon-bar {
		opacity: 0.6;
		background: $white;
		width: 30px;
		border-radius: 0px;
		margin-top: 8px;
		transition: 0.2s $easeInOutQuart;
		&:first-child {
			margin-top: 0px;
			transform: rotate(45deg) translate(7px, 7px);
			@include transform-origin(center);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:last-child {
			transform: rotate(-45deg) translate(7px, -7px);
			@include transform-origin(center);
		}
	}
	&.collapsed {
		.icon-bar {
			opacity: 1;
			&:first-child {
				transform: none;
			}
			&:nth-child(2) {
				opacity: 1;
			}
			&:last-child {
				transform: none;
			}
		}
	}
	&:focus,
	&:visited,
	&:hover {
		background: transparent;
	}
}
