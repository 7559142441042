#page-contact {
    .intro {
        margin-bottom: 70px;

        &__title {
            margin-bottom: 70px;
        }

        &__hook {
            margin-bottom: 35px;
        }

        &__card {
            background-color: $white;
            padding: 40px;
            box-shadow: $shadow_box;
            margin-bottom: 20px;
            height: 340px;

            @media screen and (max-width: $screen-md-max){ 
                height: 370px;
            }

            @media screen and (max-width: $screen-sm-max ){ 
                height: auto;
            }

            &__title {
                margin-bottom: 20px;
            }
        }
    }

    .vigeo {
        margin-bottom: 70px;

        &__title {
            margin-bottom: 30px;
            line-height: 53px;

            @media screen and (max-width: $screen-sm-max){ 
                font-size: 24px;
                line-height: 26px;
            }
        }

        &__image {
            display: block;
            margin: 0 auto 30px auto;
        }

        &__hook {
            margin-bottom: 70px;
        }

        &__testimony {
            margin-bottom: 35px;
            position: relative;

            &::before,
            &::after {
                content: "\2018\2019";
                position: absolute;
                font-size: 120px;
                letter-spacing: -7px;
                color: $lightGrey;
                font-family: $font_play;
                height: 40px;
                line-height: 96px;
            }

            &::before {
                transform: translate(-100%, -100%);
                left: 0;
                top: 0;
            }

            &::after {
                transform: translate(100%, 100%);
                right: 0;
                bottom: 0;
            }
        }
    }

    .pri {
        &__intro {
            margin-bottom: 70px;
        }

        &__title {
            margin-bottom: 30px;
            line-height: 53px;

            @media screen and (max-width: $screen-sm-max){ 
                font-size: 24px;
                line-height: 26px;
            }
        }

        &__image {
            display: block;
            margin: 0 auto 0 auto;
        }

        &__card {
            display: flex;
            align-items: center;
            margin-bottom: 70px;

            @media screen and (max-width: $screen-xs-max){ 
                flex-direction: column;
                justify-content: center;
            }

            &__number {
                font-size: 54px;
                margin-right: 10px;

                @media screen and (max-width: $screen-xs-max){ 
                    margin: 0 0 20px 0;
                }
            }

            &__text {
                @media screen and (max-width: $screen-xs-max){ 
                    text-align: center;
                }
            }
        }
    }

    .download { 
        display: flex;
        justify-content: center;

        @media screen and (max-width: $screen-sm-max){ 
            flex-direction: column;
            align-items: center;
        }

        a {
            text-decoration: none;
            color: $grey;
        }

        &__card {
            background-color: $white;
            padding: 20px 60px;
            
            width: 262px;
            font-weight: 400;
            font-size: 18px;

            transition: .6s $easeInOutQuart;

            &:hover {
                box-shadow: $shadow_box;
            }

            &__hook {
                margin-bottom: 40px;
            }

            &__image {
                display: block;
                margin: 0 auto 30px auto;
            }
        }
    }
}
