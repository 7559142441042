.list-inline--flex{
	display: inline-flex;
	width: 100%;
}
.list-inline--sb{
	justify-content: space-between; 
}

.list-inline-item{
	&--left{
		float: left; 
	}
	&--right{
		float: right; 
	}
}