.sidebar {
	color: $bleu_dark;
	margin: 0 0 10px;
	font-family: $font_play;
	font-weight: 400;
	font-size: 14px;
	text-transform: uppercase;
	padding-top: 50px;

	.sidebar-item {
		text-transform: uppercase;
		padding-bottom: 20px;
		.sidebar-item-title {
			margin-top: 10px;
			margin-bottom: 0;
		}
		span {
			margin-bottom: 0;
			padding-top: 5px;
			display: block;
		}
	}
}

.rs {
	.in {
		background-image: url("../img/icons/rs1.svg");
	}
	.em {
		background-image: url("../img/icons/rs2.svg");
	}
	.tw {
		background-image: url("../img/icons/rs3.svg");
	}
	.fb {
		background-image: url("../img/icons/rs4.svg");
	}
	a {
		height: 24px;
		width: 24px;
		display: inline-block;
		&:hover {
			opacity: 0.7;
			transition: all ease 0.3s;
		}
	}
}
.pdf {
	background-repeat: no-repeat;
	height: 24px;
	width: 24px;
	display: inline-block;
	img {
		height: 31px;
		&:hover {
			opacity: 0.7;
			transition: all ease 0.3s;
		}
	}
}
