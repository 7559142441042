/*!
Theme Name: Adveris Starter Theme
Author: Eddy SAMY
Description: Starter Theme with Bootstrap to use with Timber
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Adveris
Tags:

*/

@import "bourbon";

/* ==========================================================================
   Bootstrap
   ========================================================================== */
@import "bootstrap";

/* ==========================================================================
   Layout
   ========================================================================== */

@import "base/variables";
@import "base/font";
@import "base/base";


/* ==========================================================================
   Layout
   ========================================================================== */
@import "layout/btn";
@import "layout/title";
@import "layout/text";

@import "layout/header";
@import "layout/banner";
@import "layout/forms";
@import "layout/sidebar";
@import "layout/list";
@import "layout/breadcrumb";

@import "layout/content";
@import "layout/tab";
@import "layout/graff";
@import "layout/docs";
@import "layout/actu";
@import "layout/team";
@import "layout/section";

@import "layout/footer";



/* ==========================================================================
   Page
   ========================================================================== */
@import "pages/home";
@import "pages/contact";
@import "pages/actualites";
@import "pages/single_actu";
@import "pages/esg";
