
html, body{
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


body{
    font-size: 16px;
    color: $grey;
    font-weight: 300;
    font-family: $font_defautl;

    // @media screen and (max-width: $screen-sm-max) {
    //     color: $bleu_dark !important;
    //     font-weight: 400;
    // }
}





.mb-no{ margin-bottom: 0px!important; }
.mb-xs{ margin-bottom: 5px!important; }
.mb-sm{ margin-bottom: 15px!important; }
.mb-md{ margin-bottom: 30px!important; }
.mb-lg{ margin-bottom: 50px!important; }

.mr-no{ margin-right: 0px!important; }
.mr-xs{ margin-right: 5px!important; }
.mr-sm{ margin-right: 15px!important; }
.mr-md{ margin-right: 30px!important; }
.mr-lg{ margin-right: 50px!important; }

.ml-no{ margin-left: 0px!important; }
.ml-xs{ margin-left: 5px!important; }
.ml-sm{ margin-left: 15px!important; }
.ml-md{ margin-left: 30px!important; }
.ml-lg{ margin-left: 50px!important; }

.mt-no{ margin-top: 0px!important; }
.mt-xs{ margin-top: 5px!important; }
.mt-sm{ margin-top: 15px!important; }
.mt-md{ margin-top: 30px!important; }
.mt-lg{ margin-top: 50px!important; }

.pb-no{ padding-bottom: 0px!important; }
.pb-xs{ padding-bottom: 5px!important; }
.pb-sm{ padding-bottom: 15px!important; }
.pb-md{ padding-bottom: 30px!important; }
.pb-lg{ padding-bottom: 50px!important; }

.pr-no{ padding-right: 0px!important; }
.pr-xs{ padding-right: 5px!important; }
.pr-sm{ padding-right: 15px!important; }
.pr-md{ padding-right: 30px!important; }
.pr-lg{ padding-right: 50px!important; }

.pl-no{ padding-left: 0px!important; }
.pl-xs{ padding-left: 5px!important; }
.pl-sm{ padding-left: 15px!important; }
.pl-md{ padding-left: 30px!important; }
.pl-lg{ padding-left: 50px!important; }

.pt-no{ padding-top: 0px!important; }
.pt-xs{ padding-top: 5px!important; }
.pt-sm{ padding-top: 15px!important; }
.pt-md{ padding-top: 30px!important; }
.pt-lg{ padding-top: 50px!important; }

.pad_t{padding-top: 50px;}
.pad_l{ padding-left: 50px;}
.pad_r{padding-right: 50px;}
.pad_b{ padding-bottom: 50px;}

.full{ width: 100%; }

#loader{
    .load-out{
        height: 100vh;
        width: 100%;
        background: $white; 
        position: fixed; 
        top: 0;
        left: 0;
        z-index: 9999;
        visibility: hidden;
    }   
    .load-in{
        height: 100vh;
        width: 100%;
        background: $white; 
        position: fixed; 
        top: 0;
        right: 0;
        z-index: 9999;
        display: flex; 
        justify-content: center;
        align-items: center;

        >img{
            width: 190px;
            height: auto; 
        }
    }
}

.popin-settings-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100%;
    height: 100%; 
    background-color: #000;
    background-image: url('../img/bg-poppin-2.jpg');
    background-size: cover;  
    align-items: center;
    overflow-y: scroll;
    .container-popin{
        //min-height: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: white;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 50px 0;
        width: 90%;

        @media screen and (max-width: $screen-sm-max) {
            width: calc(100% - 30px);
            justify-content: flex-start;
        }

    }
    .loader{
        background-color: rgba(255,255,255,0.8);
        position: fixed;
        top:0;
        left:0;
        height: 100vh;
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 2;
        i{
            font-size: 45px;
            animation: rotating 1s linear infinite;
        }
    }
    @media screen and (max-width: $screen-sm-max){
        display: block;
        .container{
            // height: 100%;
            // padding-top: 15px;
            // padding-bottom: 15px;
        }
    }
    .popin-content{
        padding: 10px 30px;
        background-color: #fff;
        display: none;
        //overflow: scroll;

        @media screen and (max-width: $screen-sm-max){ 
            //overflow: scroll;
            padding: 0px 30px;
            //height: 100%;
            .btn-squarred{
                width: 50%;
                float: left;
                padding: 20px;
            }
        }
        &.active{
            display: block;
        }
        &.step-2{
            @media screen and (max-width: $screen-sm-max){
                .pays_selected{
                     .btn-squarred{
                         float: none;
                    }
                }
            }
        }
        &.step-3{
            
        }
    }

}

