.entry-content {
	margin: 40px 0;
	&--small {
		width: 55%;
		padding-left: 70px;
		@media screen and (max-width: $screen-sm-max) {
			padding-left: 0px;
		}
		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
		}
	}
}

.row--flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
}

.clear {
	clear: both;
}

.cf_list_num {
	padding: 0px;
	margin: 0 0 40px;
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	@media screen and (max-width: $screen-sm-max) {
		margin: 20px 0 40px;
	}
	.cf_list_num-item {
		flex: 1;
		padding: 0 25px;
		list-style: none;
		text-align: center;
		position: relative;
		@media screen and (max-width: $screen-sm-max) {
			padding: 0 10px;
		}
		&__num {
			color: $orange;
			font-family: $font_play;
			@extend .cf_title--h1;
			font-weight: 700;
			letter-spacing: 2px;
			margin-bottom: 0px;
		}
		&__text {
		}

		&__img {
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			margin: auto;
			max-width: 80px;
		}
		&:nth-child(1) {
			padding-left: 0;

			.cf_list_num-item__img {
				margin-left: 25px;
			}
		}
	}
}

.content {
	position: relative;
	z-index: 10;
}

.list-img_paral {
	.list-img_paral-item {
		position: absolute;
		margin: auto;
		overflow: hidden;
		z-index: 1;
		&--first {
			width: 330px;
			height: 415px;

			right: 200px;
			top: 210px;

			@media screen and (max-width: $screen-md-max) {
				right: 100px;
			}
			@media screen and (max-width: $screen-sm-max) {
				width: 250px;
				height: 335px;
				top: auto;
				bottom: 90px;
				right: 0;
			}

			@media screen and (max-width: $screen-xs-max) {
				position: relative;
				width: 100%;
				top: auto;
				bottom: 0;
				height: 270px;
				margin-top: 80px;
				.list-img_paral-item__bg {
					background-image: attr(data-image-src);
				}
			}
		}
		&--second {
			width: 390px;
			height: 560px;
			right: 0;
			top: 70px;
			@media screen and (max-width: $screen-md-max) {
			}
			@media screen and (max-width: $screen-sm-max) {
				width: 290px;
				height: 420px;
			}

			@media screen and (max-width: $screen-xs-max) {
				top: auto;
				bottom: 250px;
				opacity: 0.2;
			}
		}
		&__bg {
			height: calc(100% + 60px);
			width: calc(100% + 60px);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}
}

.list-inline > li {
	padding-bottom: 10px;
}

.list_default {
	padding: 0px;
	margin: 40px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	.list_item {
		position: relative;
		display: block;
		a {
			padding: 5px 15px;
			color: $grey;
			font-weight: 400;
			display: block;

			&.active {
				color: $orange;
			}
		}

		&:before {
			content: "";
			position: absolute;
			display: block;
			margin: 0 5px;
			height: 50%;
			width: 1px;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			background-color: $grey;

			@media screen and (max-width: $screen-sm-max) {
				display: none;
			}
		}

		&:first-child {
			&:before {
				display: none;
			}
		}

		@media screen and (max-width: $screen-sm-max) {
			width: 50%;
			text-align: center;
		}
	}

	&--bottom {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	&--top {
		margin-top: 20px;
		margin-bottom: 0px;
		.list_item {
			&:first-child {
				&:before {
					display: block;
					background-color: $orange;
				}
			}
			&:after {
				content: "";
				position: absolute;
				display: block;
				margin: 0 5px;
				height: 50%;
				width: 1px;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				background-color: $orange;
			}
		}
	}
}

.cf_block {
	display: block;
	border-radius: 13px;
	padding: 40px;

	.cf_block_list {
		margin-top: 40px;
	}
}

.selectors {
	label {
		margin-left: 30px;
		color: #909090;
		&:before {
			content: "|";
			margin-right: 10px;
		}
	}
	span {
		cursor: pointer;
		margin-left: 10px;
		color: #909090;
		&.active {
			color: $orange;
		}
		&:hover {
			color: $orange;
		}
	}
}

.filter-form-group {
	width: 100%;
	text-align: left;

	label {
		display: block;
	}

	select {
		width: 100%;
		height: 40px;
		-webkit-appearance: none;
		padding: 0px 0px;
		border-radius: 0;
		background-image: url("../img/icons/bottom.svg");
		background-repeat: no-repeat;
		background-size: 15px;
		background-position: calc(100% - 15px) center;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid black;

		&:focus {
			outline: none;
		}
	}

	@media (max-width: 992px) {
		//margin-bottom: 30px;
	}

	&.custom-error {
		color: red;
		border-bottom: 1px solid red;
	}
}

.legals-valid {
	&.custom-error {
		color: red;
	}
}

.mb-sm {
	margin-bottom: 15px;
}
.mb-md {
	margin-bottom: 30px;
}
.mb-lg {
	margin-bottom: 50px;
}
.c-bleu_dark {
	color: $bleu_dark;
}
