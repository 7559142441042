.cf_news {
	position: relative;
	margin: 40px 0 80px 0;

	&:after {
		content: "";
		position: absolute;
		background-color: $bleu;
		height: 2px;
		left: 0;
		width: 100%;
		top: 50%;
		z-index: 1;
		@media screen and (max-width: $screen-sm-max) {
			display: none;
		}
	}

	.cf_news--title {
		position: absolute;
		top: -30px;
		left: 0;
		right: 0;
		@media screen and (max-width: $screen-sm-max) {
			position: relative;
			top: auto;
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		margin: 0 0 40px 0;
	}
}
.cf_news_list-ctr {
	@extend .container;
	@media screen and (max-width: $screen-md-max) {
		width: 100%;
	}
}
.cf_news_list {
	padding: 0px;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-between;

	@media screen and (max-width: $screen-sm-max) {
		flex-direction: column;
	}

	.cf_news-item {
		list-style: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		z-index: 5;

		@media screen and (max-width: $screen-sm-max) {
			flex-direction: row;
			margin: 30px 0;
		}

		&:before {
			content: "";
			position: absolute;
			background-color: $grey;
			border-radius: 100%;
			border: 5px solid $white;
			height: 23px;
			width: 23px;
			left: 0;
			right: 0;
			top: 50%;
			margin: auto;
			transition: 0.1s linear 0.3s;
			transform: translateY(-50%);
		}

		.cf_news-item_date {
			position: absolute;
			top: 230px;
			bottom: auto;
			width: 100%;
			text-align: center;
			font-weight: 500;
			background-color: $white;
			transition: 0.1s linear 0.4s;

			@media screen and (max-width: $screen-sm-max) {
				position: relative;
				top: auto;
				bottom: auto;
				order: 2;
				width: 50%;
			}
			@media screen and (max-width: $screen-xs-max) {
				width: 25%;
			}
			&:before {
				content: "";
				position: absolute;
				background-color: $orange;
				height: 10px;
				width: 2px;
				left: 50%;
				bottom: -100px;
				margin: auto;
				z-index: -1;
				transition: 0.3s $easeInOutQuart 0.05s;
				transform: translateX(-2px);

				@media screen and (max-width: $screen-sm-max) {
					display: none;
				}
			}
		}
		.cf_news-item_block {
			order: 2;
			width: 350px;
			height: 215px;
			overflow: hidden;
			position: relative;
			z-index: 10;
			transition: 0.2s $easeInOutQuart 0.1s;
			cursor: pointer;
			@media screen and (max-width: $screen-md-max) {
				width: 320px;
			}
			@media screen and (max-width: $screen-sm-max) {
				width: 50%;
				height: 245px;
			}
			@media screen and (max-width: $screen-xs-max) {
				width: 75%;
			}
			.cf_news-item_block__bg {
				position: absolute;
				z-index: 10;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
				background-repeat: no-repeat;
				background-size: cover;
				background-color: $grey;
			}
			.cf_news-item_block__hover {
				background: $white;
				position: absolute;
				z-index: 15;
				left: 0;
				right: 0;
				bottom: 0;
				transition: 0.4s $easeInOutQuart;
				transform: translateY(100%);
				@media screen and (max-width: $screen-sm-max) {
					transform: translateY(0%);
				}
				h3 {
					margin: 20px 10px;
					text-transform: uppercase;
					color: $bleu;
					font-size: 18px;
					line-height: 25px;
				}
			}
		}

		&:first-child,
		&:last-child {
			justify-content: flex-start;
			padding-bottom: 340px;

			@media screen and (max-width: $screen-sm-max) {
				padding-bottom: 0px;
				margin: 30px 0;
			}
			.cf_news-item_date {
				bottom: 230px;
				top: auto;
				@media screen and (max-width: $screen-sm-max) {
					top: auto;
					bottom: auto;
				}
				&:before {
					top: -100px;
					bottom: auto;
				}
			}
			.cf_news-item_block {
				order: 1;
			}
		}

		&:hover {
			&:before {
				background-color: $orange;
			}

			.cf_news-item_date {
				color: $orange;
				&:before {
					height: 100px;
				}
			}
			.cf_news-item_block {
				box-shadow: $shadow_box;
				.cf_news-item_block__hover {
					transform: translateY(0%);
				}
			}
		}
	}
}

.more-articles {
	clear: both;
	h2 {
		margin: 50px 0;
	}
	.other-article {
		margin-top: 30px;
		margin-bottom: 30px;
		a {
			text-decoration: none;
		}
		.img {
			width: 100%;
			height: 213px;
			background-size: cover;
			display: block;

			transition: all $easeInOutQuart 0.3s;
			transform: translateY(0px);

			@media screen and (max-width: $screen-sm-max) {
				transform: none;
			}
		}

		.date {
			text-align: center;
			color: $orange;
			width: 100%;
			opacity: 0;

			@extend .cf_upp;
			@extend .cf_size--md;
			@extend .cf_weight--md;

			transition: all $easeInOutQuart 0.3s;
			transform: translateY(-40px);

			@media screen and (max-width: $screen-sm-max) {
				margin-top: 20px;
				opacity: 1;
				transform: none;
			}
		}
		.title {
			text-align: center;
			padding: 4px 10px;
			text-decoration: none;
			color: $bleu_dark;
			opacity: 0;

			@extend .cf_upp;
			@extend .cf_size--md;
			@extend .cf_weight--md;

			transition: all $easeInOutQuart 0.3s;
			transform: translateY(-50px);

			@media screen and (max-width: $screen-sm-max) {
				opacity: 1;
				transform: none;
			}
		}
		&:hover {
			transition: all ease 0.3s;
			.img {
				box-shadow: $shadow_box;
				transform: translateY(-30px);
				@media screen and (max-width: $screen-sm-max) {
					transform: none;
				}
			}
			.date {
				transform: translateY(0);
				opacity: 1;
			}
			.title {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
