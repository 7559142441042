.cf_tab_inner {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	@extend .entry-content;
	@media (max-width: $screen-sm-max) {
		overflow-x: scroll;
	}
}

.cf_tab_title {
	th {
		color: $bleu;
		padding: 15px;
		font-weight: 300;
		border-right: 1px solid $bleu;
		font-weight: 600;
		&:last-child {
			border-right: 0px;
		}
	}

	&--name {
		background: $bleu;
		.name__fond {
			text-transform: uppercase;
			padding: 12px 20px;
			font-weight: 300;
			color: $white;
			text-align: left;
		}
	}
}

.cf_tab_item {
	td {
		padding: 5px 20px;
		font-size: 14px;
		font-weight: 500;
		line-height: 15px;
		border-right: 1px solid $bleu;
		&:last-child {
			border-right: 0px;
		}
	}
}

.cf_tab {
	width: 100%;
	display: inline-table;
	margin-bottom: 15px;
	border: 1px solid $bleu;

	tr {
		width: 100%;
	}
	td,
	th {
		width: 25%;
	}

	// PAge fond
	&--page {
		td,
		th {
			text-align: center;
			&:nth-child(1) {
				width: 30%;
				text-align: left;
				font-size: 15px;
			}
			&:nth-child(2) {
				width: 15%;
			}
			&:nth-child(3) {
				width: 20%;
			}
			&:nth-child(4) {
				width: 20%;
			}
			&:nth-child(5) {
				width: 15%;
			}
		}
		tr {
			&:nth-child(2) {
				td {
					padding-top: 15px;
				}
			}
			&:last-child {
				td {
					//padding-bottom: 15px;
				}
			}
		}
		.cf_tab_title {
			th {
				padding: 10px;
				text-align: center !important;
			}
		}
	}
	// single fond
	&--single {
		th {
			text-align: center;
		}
		tr {
			border-bottom: 1px solid $bleu;
			p {
				color: $bleu;
				margin: 6px auto;
			}
			&:nth-child(2n + 1) {
				p {
					opacity: 0.7;
				}
			}
		}
	}
	&--home {
		border: 1px solid $white;
		.cf_tab_title {
			border-bottom: 1px solid $white;
			th {
				padding: 15px 10px;
				font-size: 14px;
				border-right: 1px solid $white;
				color: $white;
			}
		}
		.cf_tab_item {
			td {
				border-right: 1px solid $white;
				color: $white;
				font-weight: 300;
				padding: 5px 10px;
				font-size: 13px;
				&:last-child {
					border-right: 0px;
				}
				p {
					margin: auto;
					@media screen and (max-width: $screen-xs-max) {
						word-break: break-all;
					}
				}
			}
		}

		.cf_tab_2 {
			width: 20%;
		}
		.cf_tab_5 {
			width: 40%;
			@media screen and (max-width: $screen-xs-max) {
				width: auto;
			}
		}
	}
}

// PAGE HOME
.list-tab-fond-ctr {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: row;
	@extend .entry-content;
}

.list-tab-fond {
	.list-tab-fond__category {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 30px;
		@media screen and (max-width: $screen-xs-max) {
			flex-direction: column;
		}

		.list-tab-fond__category-name {
			width: 70px;
			background: #142c4e;
			border-left: 1px solid $white;
			border-bottom: 1px solid $white;
			border-top: 1px solid $white;
			position: relative;
			@media screen and (max-width: $screen-xs-max) {
				width: 100%;
				height: auto;
				text-align: center;
				border-right: 1px solid white;
			}
			p {
				transform: rotate(-90deg) translate(-50%, 35px);
				transform-origin: left;
				color: white;
				font-size: 12px;
				width: max-content;
				display: inherit;
				text-align: center;
				margin-bottom: 0;
				position: absolute;
				text-transform: uppercase;
				top: 50%;
				font-weight: 600;
				@media screen and (max-width: $screen-xs-max) {
					transform: none;
					position: relative;
					top: 0;
					text-align: center;
					padding: 15px;
					margin: auto;
				}
			}
		}
		.list-tab-fond__category-content {
			width: calc(100% - 70px);
			font-size: 12px;
			@media screen and (max-width: $screen-xs-max) {
				width: 100%;
			}
			.name__fond {
				border-left: 1px solid $white;
				border-top: 1px solid $white;
				border-right: 1px solid $white;
				margin: 0;
				padding: 10px 10px 10px 20px;
				text-transform: uppercase;
				font-size: 13px;
				color: $white;
				text-align: left;
			}
			.cf_tab_inner {
				position: relative;
				padding: 0px;
				.cf_tab {
					margin-bottom: 0px;
				}
			}
		}
	}

	.cf_tab_inner {
		margin: 0px;
	}
	.list-inline {
		text-align: right;
	}
}

.cf_tab_inner--home {
	.cf_tab_ctr {
		position: relative;
	}

	.cf_tab_inner_hover {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: 0.4s $easeInOutQuart;
		.list-inline {
			transition: 0.4s $easeInOutQuart;
			transform: translateY(30px);
		}
	}
	.cf_tab--home {
		transition: 0.4s $easeInOutQuart;
		.cf_tab_title,
		.cf_tab_item,
		td,
		tr,
		th {
			transition: 0.4s $easeInOutQuart;
		}
	}
	.name__fond {
		font-weight: 600;
	}
	&:hover {
		.cf_tab--home {
			background: $white;
			box-shadow: $shadow_box;

			.cf_tab_title,
			.cf_tab_item {
				background: $white;
				border-color: $bleu;

				td,
				tr,
				th {
					border-color: $bleu;
					color: $bleu;
					&.name__fond {
						border-left: 0px;
						border-right: 0px;
					}
				}
			}
		}
		.cf_tab_inner_hover {
			opacity: 1;
			.list-inline {
				transform: translateY(0);
			}
		}
	}
}
.tab--indic {
	padding-left: 30px;
	padding-right: 30px;
}
.selecFund {
	-webkit-appearance: none;
	padding: 9px;
	border: 0;
	background: none;
	position: relative;
}
