.cf_header {
	height: calc(100vh - 100px);
	margin-top: 100px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	overflow: hidden;
	width: 100%;
	position: relative;

	@media screen and (max-width: $screen-md-max) {
		height: calc(100vh - 70px);
		margin-top: 70px;
	}
	@media screen and (max-width: $screen-sm-max) {
		height: calc(100vh - 50px);
		margin-top: 50px;
	}
	.cf_content--center {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		position: relative;
		z-index: 2;
	}
}

.cf_header--img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.cf_single {
	background-size: cover;
	background-position: center center;
	height: 400px;
}
