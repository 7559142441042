/*
Fonts*/
$font_play: "Work Sans", sans-serif;
$font_defautl: "Work Sans", sans-serif;
$font-family: $font_play;

/*
Colors*/
$black: #000000;
$white: #ffffff;
$gray: #333333;
$grey: #404040;
$lightGrey: #dedede;
$lightOrange: #c25c05;
$green: #9ed44a;

$text-color: $grey;

$bleu: #2d2e74;
$bleu_dark: #030559;

$orange: #d5a93f;
$orange-light: #f5e2cc;
$color-linkedin: #0083c1;

//
$shadow_box: 0px 12px 36px 10px rgba(158, 124, 74, 0.18);

/* === Easings === */
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
