$fonts: (
  work-sans: (
    family: "Work Sans",
    format: "woff2",
    ext: "woff2",
    styles: (
      normal: (
        400,
        500,
        600,
        700
      )
    )
  )
);

@each $font, $options in $fonts {
  @each $style, $weights in map-get($options, "styles") {
    $suffix: if($style != "normal", "-" + $style, "");

    @each $weight in $weights {
      @font-face {
        font-family: map-get($options, "family");
        src: url("../..assets.fonts/#{$font}/#{$weight}#{$suffix}.#{map-get($options, "ext")}")
          format(map-get($options, "format"));
        font-weight: $weight;
        font-style: $style;
        font-stretch: normal;
      }
    }
  }
}
