#footer {
	background-color: $bleu_dark;
	height: 110px;
	.logo-svg-footer {
		margin: 10px 0;
		max-width: 60px;
	}
	.item-footer {
		display: table;
		height: 110px;
		.table-block {
			display: table-cell;
			vertical-align: middle;
			color: $white;
			font-size: 12px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 2.4px;
		}
		a {
			color: $white;
			font-size: 12px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 2.4px;
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		height: auto;
		.legals {
			.row {
				display: flex;
				flex-direction: column;
				.item-footer {
					width: 100%;
					height: auto;

					&:nth-child(1) {
						order: 2;
					}
					&:nth-child(2) {
						order: 1;
					}
					&:nth-child(3) {
						order: 3;
					}

					.table-block {
						display: block;
						padding: 15px;
					}
				}
			}
		}
	}
}
