.cf-form {
	.cf_form_row {
		padding-top: 20px;
		padding-bottom: 10px;
		br {
			display: none;
		}

		&.cf-form__input {
			position: relative;

			.placeholder {
				color: $grey;
				font-weight: 400;
				text-transform: uppercase;
				opacity: 0.5;
				font-size: 13px;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				&-label {
					position: absolute;
					top: 32px;
					left: 0;
					z-index: -1;
					margin: 0;
					transition: 0.4s $easeOutQuart;
					transform: translateY(-50%);

					&.active {
						top: 3px;
						left: 0px;
						font-size: 12px;
						color: $orange;
						transform: translateY(0);

						@media screen and (max-width: $screen-sm-max) {
							transform: translate(0, 0);
							top: 15px;
							left: calc(100% - 150px);
							font-style: italic;
							text-align: right;
						}
					}
				}
			}

			input {
				width: 100%;
				border-top: 0px;
				border-right: 0px;
				border-left: 0px;
				border-bottom: 1px solid $orange;
				background: transparent;
				position: relative;
				z-index: 1;
				&:focus {
					outline: none;
				}
			}

			textarea {
				border: 1px solid $orange;
				max-width: 100%;
				width: 100%;
				min-height: 75px;
				background: transparent;
				padding: 10px;
				&:focus {
					outline: none;
				}
			}
		}
		&.cf-form__select {
			position: relative;

			select {
				width: 100%;
				background: transparent;
				border-top: 0px;
				border-right: 0px;
				border-left: 0px;
				border-bottom: 1px solid $orange;
				border-radius: 0px;
				-webkit-appearance: none;
				&:focus {
					outline: none;
				}
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 5px;
				width: 25px;
				background-image: url("../img/icons/arrow.svg");
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 18px;
				transition: 0.4s $easeInOutQuart;
				transform: rotate(90deg);
			}
		}
	}
	input[type="submit"] {
		min-width: 100%;
		&:focus {
			outline: none;
		}
	}
}
div.wpcf7 .ajax-loader {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 60px;
	background-repeat: no-repeat;
	background-position: center;
}
div.div.wpcf7-spam-blocked {
	font-size: 13px;
	border: none;
	color: #ffffff;
	background: #ffa500;
	padding: 20px;
	margin: 0;
	display: inline-flex;
	width: 100%;
}
div.wpcf7-mail-sent-ok {
	font-size: 13px;
	border: none;
	color: #ffffff;
	background: #398f14;
	padding: 20px;
	margin: 0;
	display: inline-flex;
	width: 100%;
}
div.wpcf7-validation-errors {
	font-size: 13px;
	border: none;
	color: #ffffff;
	background: #f44336;
	padding: 20px;
	margin: 0;
	display: inline-flex;
	width: 100%;
}
span.wpcf7-not-valid-tip {
	color: #f44336;
	font-size: 13px;
	margin-top: 2px;
	font-style: italic;
	text-align: right;
}
