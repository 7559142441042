.ml-breadcrumb{
	ul{
		list-style: none;
		padding: 0px; 
		margin: 0px;
		li{
			display: inline-block;
			padding-right: 10px;

			@extend .cf_size--sm;
			a{
				@extend .cf_size--sm;
				color: $orange; 
				text-decoration: underline;
			}
			&:before{
				content : '>';
				position: relative; 
				display: inline-block; 
				margin-right: 10px;
			}
			&:first-child{
				&:before{
					display: none;
				}
			}
		}
	}
}