.cf_title{
    font-family: $font_play;
    letter-spacing: 2px;
    margin: 0 auto; 
	&--h1{
		font-size: 80px;
		font-weight: 700;
	    @media screen and (max-width: $screen-sm-max) {
		    font-size: 45px;
		    line-height: 62px;
		}
	}
	&--h2{
		font-size: 67px;
		font-weight: 700;

		@media screen and (max-width: $screen-sm-max) {
		   font-size: 40px;
		   line-height: 54px;
		}
	}
	&--h3{
		font-size: 50px;
    	line-height: 54px;
	    font-weight: 700;
	     @media screen and (max-width: $screen-sm-max) {
		   font-size: 35px;
		    line-height: 28px;
		}
	}
	&--h4{
		font-size: 24px;
		line-height: 48px;
	    font-weight: 300;
	}

}